.captcha-box {
  flex-wrap: wrap;

  @include bp-large {
    flex-wrap: unset;
  }

  .icons {
    cursor: pointer;
    padding: 0 0.5rem;
  }

  .icons svg {
    color: $yellow;
  }
}

#captchaJS {
  canvas {
    border: 1px solid $yellow;
  }
}
