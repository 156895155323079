.home .section__title {
  font-family: Dinot;
}

.home .section:not(.section__blog) {
  background: {
    size: cover;
    position: 50% 50%;
    repeat: no-repeat;
  }

  height: 100vh;
  position: relative;
  padding-top: 5rem;

  .section__content {
    color: white;

    .title {
      text-transform: uppercase;
      font-size: 1.75rem;
      margin-bottom: 1.5rem;

      @include bp-medium {
        max-width: 50%;
      }
      @include bp-large {
        max-width: 30%;
      }
    }

    .read-more {
      color: $blue;
      text-transform: uppercase;
      font-family: DinotMedium;
    }
  }
}

// SECTION OVERLAY

#sectionOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 0;
}

// SECTION OVERLAY END

// SECTION BLOG

.home .blog-post {
  @include bp-medium {
    &__thumbnail {
      padding-right: 1.5rem;
    }

    &__meta {
      padding-left: 1.5rem;
      justify-content: center;

      .date {
        margin-bottom: 1.5rem;
      }
    }
  }
}

// SECTION BLOG END
