// toggler

.navbar-toggler {
  border: none;
  display: block !important;

  &:focus {
    outline: none;
  }

  .icon-bar {
    background: white;
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    transition: 0.3s;
  }

  .icon-bar + .icon-bar {
    margin-top: 4px;
  }

  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
  }

  .middle-bar {
    opacity: 0;
  }

  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
  }
}

.navbar-toggler.collapsed {
  .top-bar {
    transform: rotate(0);
  }

  .middle-bar {
    opacity: 1;
  }

  .bottom-bar {
    transform: rotate(0);
  }
}

// toggler end

// site navigation

.site-navigation {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 60px 30px;
  z-index: 1002;
  background: $grey;
  transition: all 0.5s;
  transform: translate3d(100%, 0, 0);
  right: 0;

  @include media-breakpoint-up(md) {
    width: 30%;
  }

  &.show {
    transform: translate3d(0, 0, 0);
  }

  ul > li {
    font-size: 1.25rem;
  }

  &__inner {
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;

    .list-inline {

      a {
        color: $blue;
      }
    }
  }
}

.site-navigation__inner .navbar-nav {
  padding-bottom: 2rem;
}

.site-navigation__inner .navbar-nav-button,
.site-navigation__inner .nav-socialmedia {
  position: absolute;
}

.site-navigation__inner .navbar-nav-button {
  bottom: 60px;

  .btn-primary:hover,
  .btn-primary:active,
  .btn-primary:focus,
  .btn-primary:not(:disabled):not(.disabled):active {
    background-color: darken($blue, 5%);
    border-color: darken($blue, 5%);
  }
}

.site-navigation__inner .nav-socialmedia {
  bottom: 0;
}

.site-navigation .navbar-toggler {
  position: absolute;
  top: 15px;
  right: 15px;
}

.site-navigation .nav-link {
  text-transform: uppercase;
  color: white;
}

// site navigation end

// .site-header > .navbar:hover {
//   background: rgba($grey-light, 0.15);
// }

.navbar-absolute {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 0;
}

.navbar-brand img {
  max-width: 150px;
}
