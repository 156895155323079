.form-wrapper {
  border: 1px solid $grey;

  &__inner {
    padding: 1rem;

    &:not(:first-child) {
      border-top: 1px solid $grey;
      padding-top: 2rem;
    }
  }

  .btn {
    text-transform: uppercase;
    font-weight: 300;
  }
}
