

// modal content

.modal-dialog .modal-content {
  background: #29a1d8;
  border-color: transparent;
  font-family: DinotLight;

  .info a {
    text-decoration: underline;
    color: white;
  }
}

// modal header

.modal-dialog .modal-header {
  position: relative;
  flex-direction: column;
  align-items: center;
  border: 0;
  color: white;
  padding-top: 2rem;

  .modal-title {
    font-family: DinotLight;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0.5rem;
    font-size: 1.75rem;
    color: white;
    margin: 0;
    padding: 0;
  }
}

.modal-dialog .modal-body {
  color: white;
  padding: {
    top: 0;
    bottom: 0;
  }

  .custom-modal-content-wrapper {
    margin: auto;
    max-width: 85%;

    .info {
      color: white;
      text-align: center;
    }
  }

  .form-wrapper__inner {
    padding: {
      left: 5rem;
      right: 5rem;
    }
  }
}

// modal footer

.modal-dialog .modal-footer {
  border: 0;
  padding-bottom: 2rem;

  .btn {
    margin: auto;
    min-width: 120px;
    font-family: DinotMedium;
  }
}

#formDownload {
  .form-control,
  .custom-select {
    background-color: transparent;
    border-color: white;
    border-radius: 0;
  }
}
