.site-footer {
  .footer__content {
    padding: 1.5rem 0;
    text-align: center;
  }

  .footer__link a {
    text-decoration: underline;
  }
}

.site-footer .nav-socialmedia a {
  color: $blue;
}
