.jscookie {
  background: $blue;
  color: white;
  font-weight: 300;
  position: fixed;
  bottom: -100%;
  z-index: 1;
  width: 100%;
  padding: 30px 0;
  box-shadow: 0 5px 20px $grey;

  strong {
    font-weight: 700;
  }

  &.active {
    bottom: 0;
  }

  &__message {
    a {
      color: white;
      text-decoration: underline;
    }
  }
}
