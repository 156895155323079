.header-hero {
  &__images {
    height: 0;
    padding-bottom: calc(50% + 2.5rem);
    overflow: hidden;
    position: relative;
    background: $grey-light;

    img {
      @extend %absolute-center-image;
    }
  }
}
