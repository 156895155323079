.section__content {
  &__carousel {
    .swiper-container {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      text-align: center;
      background: darken($grey, 15%);
      position: relative;
      padding-bottom: 33.333%; //3:1
      height: 0;
      overflow: hidden;
    }

    .swiper-slide img {
      width: auto;
      height: auto;
      max-width: 100%;
      @extend %absolute-center-image;
    }

    .swiper-pagination {
      padding: 0 2rem;
      text-align: right;
    }

    .swiper-pagination-bullet {
      background: white;
      opacity: 0.5;

      &-active {
        opacity: 1;
      }
    }
  }
}
