// .blog .navbar .navbar-toggler .icon-bar {
//   background: $grey;
// }

.blog-index {
  .section__blog {
    padding-top: 5rem;
  }

  .section__title {
    h1,
    h2 {
      color: $grey-text;
      text-transform: capitalize;
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  }
}

.section__blog {
  .section__content__featured .blog-post {
    border-bottom: 1px solid $grey;
    padding: 1.5rem 0;
    margin-bottom: 1.5rem;
  }
}

.blog-post {
  &--flex {
    display: flex;
    flex-direction: column;

    @include bp-medium {
      flex-direction: row;
    }

    > div {
      flex: 1;
      margin-bottom: 1.5rem;
    }
  }

  // blog-post__meta

  &__meta {
    display: flex;
    flex-flow: wrap column;

    .title {
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  .date {
    color: $yellow;
    display: block;
    font-family: DinotLight;
    font-weight: 300;
  }

  .read-more {
    text-transform: uppercase;
    font-family: DinotLight;
  }

  .view-more {
    text-decoration: underline;

    color: $yellow;
    font-weight: 500;
  }

  &__content__tag {
    a {
      color: $yellow;

      &:focus,
      &:hover {
        color: $grey;
      }
    }

    .list-inline {
      padding: 0 0.5rem;
      margin-bottom: 0;

      li {
        font-style: italic;
      }
    }
  }

  // blog-post__excerpt

  &__excerpt .read-more a {
    color: $yellow;

    &:hover,
    &:focus,
    &:active {
      color: white;
    }
  }
}

.section__content {
  &__posts .blog-post {
    margin-bottom: 1.5rem;

    &.card {
      border: 0;
    }

    .card-body {
      padding: 0;
    }

    .title {
      font-weight: 300;
      font-size: 1.25rem;
      padding-top: 0.5rem;
    }

    &__thumbnail {
      display: block;
      background: $grey-light;
      cursor: pointer;
    }

    &__excerpt {
      padding: 0.5rem 0;
    }
  }
}

// blog detail

.blog-detail .section {
  padding-top: 0;
}

.blog-detail {
  .blog-post {
    padding-bottom: 1.5rem;
  }

  .blog-post__content .subtitle {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 300;
  }

  .blog-post__content--media img {
    display: block;
    margin: auto;
  }
}

// blog detail end
