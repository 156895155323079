.section__content {
  &--ourvalue {
    border-color: $grey !important;
  }
}

.section__content--team {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  .subtitle {
    font-weight: 300;
  }

  .avatar {
    margin: auto;
    max-width: 100%;

    @include bp-small {
      max-width: 210px;
    }

    img {
      margin: auto;
      display: block;
      box-shadow: 0 2px 5px $grey;
    }
  }

  .content {
    padding-left: 1rem;
    flex: 1;
  }

  .btn {
    text-transform: capitalize;
  }
}

.section__content--theexpert {
  .embed-responsive {
    background: $grey-light;
  }
}
