.btn {
  border-radius: 2px;
}

.btn-primary {
  background-color: $blue;
  border-color: $blue;

  &:hover,
  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: $grey;
    border-color: $grey;
  }

  &--yellow {
    background-color: $yellow;
    border-color: $yellow;

    &,
    &:hover,
    &:focus,
    &:active {
      color: white;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: darken($yellow, 2%);
    }
  }

  &--white {
    background-color: white;
    border-color: white;

    &,
    &:hover,
    &:focus,
    &:active {
      color: $blue;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: darken($light, 2%);
    }
  }
}

.btn-outline-light {
  &,
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: $light;
    border-color: $light;
  }
}

.btn-outline-dark {
  color: $blue;
}
