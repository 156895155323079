$bp-small: 480px;
$bp-medium: 768px;
$bp-large: 1024px;
$bp-xlarge: 1280px;
$bp-full: 1600px;

@mixin bp-small {
  @media (min-width: $bp-small) {
    @content;
  }
}

@mixin bp-medium {
  @media (min-width: $bp-medium) {
    @content;
  }
}

@mixin bp-large {
  @media (min-width: $bp-large) {
    @content;
  }
}

@mixin bp-xlarge {
  @media (min-width: $bp-xlarge) {
    @content;
  }
}

@mixin bp-full {
  @media (min-width: $bp-full) {
    @content;
  }
}
