.case {
  .thumbnail {
    height: 0;
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
    display: block;

    &:before {
      content: '';
      background: rgba(black, 0.75);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }

    &:hover:before {
      opacity: 0.25;
    }

    img {
      position: absolute;

      &:not(.thumbnail__logo) {
        @extend %absolute-center-image;
      }
    }

    &:hover img:not(.thumbnail__logo) {
      transform: scale(1.1);
    }

    &__logo {
      top: 1.5rem;
      left: 1.5rem;
      max-height: 36px;
      z-index: 2;
    }
  }

  .caption .title {
    font-weight: 700;
  }
}

.section__case {
  &__detail .subtitle {
    text-transform: uppercase;
    font-weight: 500;
    padding-bottom: 1rem;
  }
}
