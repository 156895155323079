.section__joinus ul {
  padding: 0 0 0 1.5rem;

  form {
    font-weight: 300;
  }
}

.section__content {
  &--openposition {
    .title {
      font-weight: 500;
    }

    .list-group-item {
      border: 0;
    }
  }

  &--joinform {
    .title {
      color: $yellow;

      font: {
        weight: 300;
        size: 2.5rem;
      }
    }
  }
}
