.widget {
  &__title {
    text-transform: uppercase;
    font-size: 1.5rem;
  }

  &__inner {
    padding: 1.5rem 0;
    border-top: 1px solid $black;
  }
}

// widget connect

.widget-connect {
  margin-top: 3rem;

  ul {
    margin: 0;
  }

  .widget__inner {
    padding: 3rem 0;
  }

  &__icons {
    .icon {
      margin: 0 0.75rem;
      width: 50px;
      height: 50px;
    }
  }

  .toggle-icon:hover {
    color: $blue;
  }
}

.widget-connect .row {
  [class*='col-']:not(:last-child) > .btn {
    margin-bottom: 0.5rem;
  }

  .btn {
    margin-bottom: 1.5rem;
  }

  .btn > span {
    padding-right: 0.5rem;
  }
}

.widget-connect__toggler {
  margin: 3rem 0 0;

  .toggle-icon {
    cursor: pointer;
    font-size: 1.75rem;
    display: inline-block;
    vertical-align: middle;
    line-height: 60px;
  }

  &--icon {
    width: 0;
    height: 0;
    overflow: hidden;

    .icon {
      margin: auto;
    }

    .list-inline-item {
      padding: 0 1.5rem;
      margin: 0;

      a {
        &,
        &:focus,
        &:hover,
        &:active,
        &:visited {
          color: $grey;
          text-decoration: none;
        }
      }
    }

    .list-inline-item span:not(.icons) {
      font-size: 0.75rem;
    }
  }
}

// active widget

.widget-connect__toggler.active {
  .toggle-icon {
    @include bp-medium {
      border-right: 1px solid $grey;
      padding-right: 1.5rem;
    }
  }

  .widget-connect__toggler--icon {
    width: auto;
    height: auto;
  }
}

// widget: blog

.widget-blog {
  &__recent,
  &__related {
    .widget__inner {
      border-top: 0;
    }
  }

  .blog-post {
    &__meta {
      margin-bottom: 1.5rem;

      a {
        color: $grey;
      }
    }

    &__meta .title {
      font-weight: 300;
    }
  }
}

// widget: blog end
