/*!
  Project: Project Ludo
  Author: legamuka
 */

@import "vendor/bootstrap.scss";
@import "vendor/swiper.scss";
@import "vendor/toastr.scss";
@import "ludo/settings/_breakpoints.scss";
@import "ludo/settings/_color.scss";
@import "ludo/components/_btn.scss";
@import "ludo/components/_captcha.scss";
@import "ludo/components/_cookieConsent.scss";
@import "ludo/components/_footer.scss";
@import "ludo/components/_form.scss";
@import "ludo/components/_headerHero.scss";
@import "ludo/components/_icons.scss";
@import "ludo/components/_modal.scss";
@import "ludo/components/_navbar.scss";
@import "ludo/components/_preloader.scss";
@import "ludo/components/_sectionCarousel.scss";
@import "ludo/components/_utils.scss";
@import "ludo/components/_widget.scss";
@import "ludo/pages/_blog.scss";
@import "ludo/pages/_case.scss";
@import "ludo/pages/_home.scss";
@import "ludo/pages/_join.scss";
@import "ludo/pages/_ourPerspective.scss";
@import "ludo/pages/_ourstory.scss";
@import "ludo/pages/_policy.scss";
@import "ludo/pages/_subscribe.scss";

// GLOBAL

// fonts

@font-face {
  font-family: "Dinot";
  font-weight: normal;
  src: url(../assets/fonts/DINOT.otf) format("opentype");
}

@font-face {
  font-family: "DinotLight";
  font-weight: normal;
  src: url(../assets/fonts/DINOT-Light.otf) format("opentype");
}

@font-face {
  font-family: "DinotLightItalic";
  font-weight: normal;
  src: url(../assets/fonts/DINOT-LightItalic.otf) format("opentype");
}

@font-face {
  font-family: "DinotMedium";
  font-weight: normal;
  src: url(../assets/fonts/DINOT-Medium.otf) format("opentype");
}
@font-face {
  font-family: "DinotMediumItalic";
  font-weight: normal;
  src: url(../assets/fonts/DINOT-MediumItalic.otf) format("opentype");
}

@font-face {
  font-family: "DinotBold";
  font-weight: normal;
  src: url(../assets/fonts/DINOT-Bold.otf) format("opentype");
}

@font-face {
  font-family: "DinotBoldItalic";
  font-weight: normal;
  src: url(../assets/fonts/DINOT-BoldItalic.otf) format("opentype");
}

// fonts end

// helper

.fadein {
  animation-name: fadein;
  animation-duration: 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

%absolute-center-image {
  position: absolute;
  top: -25%;
  bottom: -25%;
  right: -25%;
  left: -25%;
  margin: auto;
}

.text-clamp {
  display: -webkit-box;
  overflow: hidden;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

.title {
  &--blue {
    color: $blue;
  }
}

// helper end

* {
  transition: 0.3s ease-in;
}

body {
  font-family: 'Dinot', Barlow, sans-serif;
}

a {
  color: $blue;

  &,
  &:hover {
    text-decoration: none;
  }

  &:hover,
  &:focus,
  &:active {
    color: darken($blue, 5%);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: DinotMedium;
}

// SECTION

.section {
  padding-top: 2rem;

  &__title {
    color: $grey;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-transform: uppercase;
    }
  }

  &__content {
    .title {
      text-transform: uppercase;
      position: relative;
    }

    .subtitle {
      display: block;
    }

    .title.lineart {
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
      }

      &:before {
        background: white;
        z-index: -1;
      }

      &:after {
        width: 2px;
        height: 250%;
        background: $grey;
        transform: rotate(30deg);
        z-index: -2;
      }

      &--red:after {
        background: $red;
      }

      &--blue:after {
        background: $blue;
      }

      &--yellow:after {
        background: $yellow-bright;
      }
    }

    &__post--quotes {
      background: $grey-light;
      color: $blue;
      padding: 4rem 0;

      span {
        font-size: 1.5rem;
      }
    }

    &__post--nav {
      a {
        color: $grey;
      }
    }

    // content-hero
    &__hero .hero-image {
      background: $grey-light;
      height: 0;
      overflow: hidden;
      position: relative;

      &--3by1 {
        padding-bottom: 33.33%;
      }

      &--16by9 {
        padding-bottom: 56.25%;
      }

      img {
        @extend %absolute-center-image;
      }
    }

    &__pagination {
      padding-top: 1.5rem;

      .page-link {
        color: $grey;
        border-color: transparent;

        &:focus {
          box-shadow: none;
        }
      }

      .page-item.active .page-link {
        background: $grey;
        border-color: $grey;
      }
    }
  } // .section__content end

  &__content--custom {
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    }
  } // .section__content__custom end

  .name,
  .tel {
    display: block;
    color: inherit;
  }

  .meta {
    padding: 0 0 1.5rem;

    > span {
      display: block;
    }
  }
}

.section-detail {
  padding-top: 5rem;

  .title {
    font-weight: 500;
    font-size: 1.25rem;
  }

  .section__title {
    .title {
      font-size: 1.85rem;
      font-weight: 500;
    }
  }
}

// SECTION END

// FORM

.custom-control-label {
  cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $blue;
  background-color: $blue;
}

// post-nav

.post-nav {
  a {
    font-size: 2rem;
    padding: 0 1rem;
  }
}

// post-nav end

// thumbnail-image

.thumbnail-image {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }

  &:hover img {
    transform: scale(1.1);
  }
}

// thumbnail-image end

// page-detail

.case-detail,
.blog-index {
  .site-header .navbar-toggler .icon-bar {
    background: black;
  }
}

// page-detail end

.sidebar {
  &__widget {
    border-top: 1px solid $grey;
    padding-top: 3rem;

    @include bp-large {
      border-right: 1px solid $grey;
      border-top: 0;
      padding-top: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}

.icon {
  padding: 0.5rem 0;

  &.toggle-share {
    cursor: pointer;
    width: 30px;
    height: auto;
    font-size: inherit;
    text-align: center;
    padding-right: 0.5rem;
  }
}

.share-box {
  width: 0;
  height: 0;
  overflow: hidden;

  a {
    margin: 0 2px;
    background-color: $grey;
    position: relative;
    display: inline-block;
    color: #fff;
    font-size: 1rem;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
  }

  &.active {
    width: auto;
    height: auto;
  }

  .facebook:hover {
    background: $facebook-color;
  }

  .linkedin:hover {
    background: $linkedin-color;
  }

  .twitter:hover {
    background: $twitter-color;
  }

  .youtube:hover {
    background: $youtube-color;
  }
  .whatsapp:hover {
    background: $whatsapp-color;
  }
}

// toastr custom
#toast-container.ld-toast > div {
  padding: 10px;
  width: auto;
}

#toast-container.ld-toast > .toast-success {
  background-image: none !important;
}

.ld-toast > .toast-success {
  background-color: $blue;
}

// GLOBAL END
