.icon {
  display: block;
  width: 32px;
  height: 32px;
  background: {
    repeat: no-repeat;
    size: 100%;
  }
  font-size: 0;

  &-podcast {
    background-image: url('../assets/images/icon-podcast.png');
  }

  &-info {
    background-image: url('../assets/images/icon-info.png');
  }

  &-download {
    background-image: url('../assets/images/icon-download.png');
  }

  &-phone {
    background-image: url('../assets/images/icon-phone.png');
  }

  &-direction {
    background-image: url('../assets/images/icon-direction.png');
  }

  &-email {
    background-image: url('../assets/images/icon-email.png');
  }
}
