.our-perspective {
  .section__title h1 {
    text-transform: uppercase;
  }

  .section__content {
    .title {
      font-family: DinotLight;

      &:not(.title--custom) {
        color: $yellow;
      }

      &--custom {
        font-size: 1.75rem;
      }
    }

    &--custom {
      min-height: 100vh;
      margin-top: 3rem;
      padding: 3rem 0;
      color: white;

      @include bp-large {
        background-attachment: fixed;
      }

      .row > [class^='col-'] {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }

      .title {
        &:not(.title--custom) {
          font-size: 1.5rem;
          margin: auto;
          padding-bottom: 3rem;

          @include bp-medium {
            padding: {
              left: 3rem;
              right: 3rem;
            }
            max-width: 70%;
          }
        }

        &--custom {
          display: flex;
          align-items: center;

          &:before {
            content: '';
            width: 60px;
            height: 60px;
            display: block;
            background-repeat: no-repeat;
            background-size: 100%;
          }

          &.title-discover:before {
            background-image: url('../assets/images/icon-discover.png');
          }

          &.title-define:before {
            background-image: url('../assets/images/icon-define.png');
          }

          &.title-develop:before {
            background-image: url('../assets/images/icon-develop.png');
          }

          &.title-deliver:before {
            background-image: url('../assets/images/icon-deliver.png');
          }
        }
      }
    } //section__content--custom end

    .footer {
      margin: auto;
      padding-top: 3rem;

      @include bp-medium {
        max-width: 70%;
      }
    }
  }

  .widget__inner {
    border: 0;
  }

  .widget-connect {
    margin: 0;
  }
}
